enum CharType {
  Letter,
  Number,
  Symbol,
}

type KeyValue = { type: CharType, value: string };

class Key {
  readonly default: KeyValue;

  readonly shift: KeyValue;

  constructor([defaultT, defaultV]: [CharType, string], [shiftT, shiftV]: [CharType, string]) {
    this.default = { type: defaultT, value: defaultV };
    this.shift = { type: shiftT, value: shiftV };
  }

  public containsChar(char: string): 'default' | 'shift' | null {
    if (this.default.value === char) {
      return 'default';
    }
    if (this.shift.value === char) {
      return 'shift';
    }
    return null;
  }

  public isLetter(): boolean {
    return this.default.type === CharType.Letter && this.shift.type === CharType.Letter;
  }

  public char(shift = true): string {
    return shift ? this.shift.value : this.default.value;
  }
}

export {
  CharType, Key,
};
