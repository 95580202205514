import { Key } from './key';

const LayoutKeyCodes = [
  'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9', 'Digit0',

  'KeyQ', 'KeyW', 'KeyE', 'KeyR', 'KeyT', 'KeyY', 'KeyU', 'KeyI', 'KeyO', 'KeyP', 'KeyA',
  'KeyS', 'KeyD', 'KeyF', 'KeyG', 'KeyH', 'KeyJ', 'KeyK', 'KeyL', 'KeyZ', 'KeyX', 'KeyC',
  'KeyV', 'KeyB', 'KeyN', 'KeyM',

  'Backquote', 'Minus', 'Equal', 'BracketLeft', 'BracketRight', 'Semicolon', 'Quote',
  'Backslash', 'Comma', 'Period', 'Slash',

  'IntlBackslash',

  'Space',
] as const;

// type LayoutKeyCode = typeof LayoutKeyCodes;
type KeyCode<T> = T extends typeof LayoutKeyCodes[number] ?
typeof LayoutKeyCodes[number] :
typeof LayoutKeyCodes[number] |
                      'Backspace' |
                      'Enter' |
                      'ShiftLeft' |
                      'ShiftRight' |
                      'ControlLeft' |
                      'AltLeft' |
                      'AltRight' |
                      'ArrowLeft' |
                      'ArrowDown' |
                      'ArrowUp' |
                      'ArrowRight' |
                      'CapsLock' |
                      'Tab'
  ;

const charSubstituions = new Map<string, string>();
charSubstituions.set('"', '[double_quote]');
charSubstituions.set('\'', '[single_quote]');

// Keyboard layout properties
// These 4 properties define any keyboard layout

const Systems = {
  WIN: 'win-lnx',
  OSX: 'osx',
} as const;
type System = typeof Systems[keyof typeof Systems] | 'ANY';

const Formats = {
  ISO: 'iso',
  ANSI: 'ansi',
} as const;
type Format = typeof Formats[keyof typeof Formats];

const Languages = {
  EN: 'en',
  RU: 'ru',
} as const;
type Language = typeof Languages[keyof typeof Languages];

type LayoutProps = {
  system: System,
  format: Format,
  language: Language,
  name: string,
}

type LayoutKeys = Array<Map<KeyCode<null>, Key>>

// PressObject - object to exchange typing events between components
type PressObject = {
  code: KeyCode<null>,
  key: string
}

export {
  KeyCode, LayoutKeyCodes,
  Systems, System, Formats, Format, Languages, Language, LayoutProps,
  LayoutKeys,
  charSubstituions, PressObject,
};
