import { KeyCode, Formats } from '@/config/keyboards/dataTypes';
import { KeyboardLayout } from '@/config/keyboards/keyboardLayout';
import * as layoutsRU from '@/config/keyboards/layouts/ru';

type FingerPosition = 'StartPosition' | 'OutOfSpacePosition' | KeyCode<null> |
  'ShiftLeft_ISO' | 'IntlBackslash_ISO' | 'Backslash_ISO' | 'Enter_ISO';

type HandOffset = {x: number, y: number};

enum Position { Left = 'left', Right = 'right' }

type HandsProperties = {
  left: { id: string, offset: HandOffset },
  right: { id: string, offset: HandOffset },
  main: Position
};

type NextPosition = {
  key: string,
  code: KeyCode<null>,
  shift: Position | null,
  hands: HandsProperties
};

const handsConfig = {
  left: {
    keys: {
      Backquote: 2,
      Digit1: 3,
      Digit2: 4,
      Digit3: 5,
      Digit4: 6,
      Digit5: 7,
      Digit6: 8,
      KeyQ: 9,
      KeyW: 10,
      KeyE: 11,
      KeyR: 12,
      KeyT: 13,
      KeyA: 14,
      KeyS: 15,
      KeyD: 16,
      KeyF: 17,
      KeyG: 18,

      ShiftLeft_ISO: 19,
      IntlBackslash_ISO: 20,
      ShiftLeft: 19, // ANSI

      KeyZ: 21,
      KeyX: 22,
      KeyC: 23,
      KeyV: 24,
      KeyB: 25,
      Space: 26,
    } as {[keyCode in FingerPosition]: number},

    offsets: [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: -15, y: -22 },
      { x: -18, y: -22 },
      { x: -18, y: -21 },
      { x: -20, y: -21 },
      { x: -21, y: -23 },
      { x: -21, y: -21 },
      { x: -21, y: -20 },
      { x: -1, y: -9 },
      { x: -1, y: -11 },
      { x: -1, y: -12 },
      { x: -3, y: -11 },
      { x: 0, y: -10 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: -8, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: -2, y: -2 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ] as HandOffset[],
  },
  right: {
    keys: {
      Backspace: 2,
      Equal: 3,
      Minus: 4,
      Digit0: 5,
      Digit9: 6,
      Digit8: 7,
      Digit7: 8,

      Backslash: 9, // ANSI
      Enter_ISO: 9,

      BracketRight: 10,
      BracketLeft: 11,
      KeyP: 12,
      KeyO: 13,
      KeyI: 14,
      KeyU: 15,
      KeyY: 16,

      Enter: 17, // ANSI
      Backslash_ISO: 17,

      Quote: 18,
      Semicolon: 19,
      KeyL: 20,
      KeyK: 21,
      KeyJ: 22,
      KeyH: 23,
      ShiftRight: 24,
      Slash: 25,
      Period: 26,
      Comma: 27,
      KeyM: 28,
      KeyN: 29,
      Space: 30,
    } as {[keyCode in FingerPosition]: number},

    offsets: [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 4, y: -22 },
      { x: 1, y: -21 },
      { x: 2, y: -23 },
      { x: 2, y: -22 },
      { x: 2, y: -24 },
      { x: 3, y: -25 },
      { x: 4, y: -20 },
      { x: 4, y: -8 },
      { x: 1, y: -9 },
      { x: 0, y: -10 },
      { x: 0, y: -9 },
      { x: 0, y: -10 },
      { x: 1, y: -12 },
      { x: 0, y: -11 },
      { x: -4, y: -9 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 0, y: 0 },
      { x: 1, y: -5 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 1 },
      { x: 0, y: 0 },
    ] as HandOffset[],
  },
};

const getHandProps = (
  fingerPosition: FingerPosition,
  whichHand: Position,
  keyboard: KeyboardLayout,
): { id: string, offset: HandOffset } => {
  if (keyboard.props.format === Formats.ISO) {
    switch (fingerPosition) {
      case 'ShiftLeft':
        fingerPosition = 'ShiftLeft_ISO';
        break;
      case 'Enter':
        fingerPosition = 'Enter_ISO';
        break;
      case 'Backslash':
        fingerPosition = 'Backslash_ISO';
        break;
      default:
        break;
    }
  }

  let index: number | undefined;

  switch (fingerPosition) {
    case 'OutOfSpacePosition':
      index = 0;
      break;
    case 'StartPosition':
      index = 1;
      break;
    default:
      index = handsConfig[whichHand].keys[fingerPosition];
      break;
  }

  return {
    id: `hand-${whichHand}-${index}`,
    offset: handsConfig[whichHand].offsets[index],
  };
};

const getStartHandsProps = (keyboard: KeyboardLayout): HandsProperties => ({
  left: getHandProps('StartPosition', Position.Left, keyboard),
  right: getHandProps('StartPosition', Position.Right, keyboard),
  main: Position.Left,
});


const nextPosition = (
  charToType: string | KeyCode<null>,
  keyboard: KeyboardLayout,
  prevPressNext?: NextPosition,
): NextPosition | undefined => {
  let code: KeyCode<null> | undefined;
  let shift: Position | null = null;
  let hands: HandsProperties | undefined;

  // No Ё in Apple RussianPC ANSI layout
  if (keyboard.props.format === Formats.ANSI && keyboard.props.name === layoutsRU.names.RussianApplePC) {
    if (charToType === 'ё') {
      charToType = 'е';
    } else if (charToType === 'Ё') {
      charToType = 'Е';
    }
  }

  if (charToType === ' ') {
    code = 'Space';
    const main = (prevPressNext?.hands.main === Position.Left) ? Position.Right : Position.Left;
    const left = main === Position.Left
      ? getHandProps('Space', Position.Left, keyboard)
      : getHandProps('OutOfSpacePosition', Position.Left, keyboard);
    const right = main === Position.Right
      ? getHandProps('Space', Position.Right, keyboard)
      : getHandProps('OutOfSpacePosition', Position.Right, keyboard);

    hands = {
      left,
      right,
      main,
    };
  } else if (charToType === 'Backspace') {
    code = 'Backspace';
    const main = Position.Right;
    const left = getHandProps('StartPosition', Position.Left, keyboard);
    const right = getHandProps('Backspace', Position.Right, keyboard);

    hands = {
      left,
      right,
      main,
    };
  } else if (charToType === 'Enter') {
    code = 'Enter';
    const main = Position.Right;
    const left = getHandProps('StartPosition', Position.Left, keyboard);
    const right = getHandProps('Enter', Position.Right, keyboard);

    hands = {
      left,
      right,
      main,
    };
  } else {
    // Here is used disabling of eslint, since we work with Map -> there is only iterator, which is hard for processing
    keyboard.getAllKeys().forEach((key, keyCode) => {
      const charInKey = key.containsChar(charToType);

      if (charInKey) {
        code = keyCode;
        const withShift = charInKey === 'shift';

        let left = getHandProps('StartPosition', Position.Left, keyboard);
        let right = getHandProps('StartPosition', Position.Right, keyboard);
        let main: Position;

        if (Object.keys(handsConfig.left.keys).includes(keyCode)) {
          main = Position.Left;
          left = getHandProps(code as FingerPosition, Position.Left, keyboard);
          if (withShift) {
            shift = Position.Right;
            right = getHandProps('ShiftRight', Position.Right, keyboard);
          }
        } else if (Object.keys(handsConfig.right.keys).includes(keyCode)) {
          main = Position.Right;
          right = getHandProps(code as FingerPosition, Position.Right, keyboard);
          if (withShift) {
            shift = Position.Left;
            left = getHandProps('ShiftLeft', Position.Left, keyboard);
          }
        } else {
          return undefined;
        }

        hands = {
          left,
          right,
          main,
        };
        return null;
      }
      return null;
    });
  }

  if (!code || !hands) {
    return undefined;
  }

  return {
    key: charToType,
    shift,
    code,
    hands,
  };
};

export {
  nextPosition, NextPosition, getStartHandsProps, Position, HandsProperties,
};
