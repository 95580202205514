
  import {
    computed, ref, defineComponent, onUnmounted, onMounted, PropType, watchEffect, Ref,
  } from 'vue';
  import { useStore } from 'vuex';
  import HandsSVG from '@/components/HandsSVG.vue';

  import { codeProxy } from '@/utils';
  import {
    KeyCode, Formats, LayoutProps, PressObject,
  } from '@/config/keyboards/dataTypes';
  import {
    KeyboardLayout,
  } from '@/config/keyboards/keyboardLayout';
  import {
    CharType,
  } from '@/config/keyboards/key';
  import { layouts as KeyboardLayouts } from '@/config/keyboards/layoutsAll';
  import {
    nextPosition, NextPosition, getStartHandsProps, Position,
  } from './utils';

  export default defineComponent({
    name: 'Keyboard',

    components: {
      HandsSVG,
    },

    props: {
      // Layout to display
      layoutProps: {
        type: Object as PropType<LayoutProps>,
        required: true,
      },

      // 'auto' will resize by Trainer breakpoints
      size: {
        type: String as PropType<'small' | 'medium' | 'large' | 'auto' | 'fit-profile'>,
        default: 'auto',
      },

      showHands: {
        type: Boolean,
        default: false,
      },

      // Interactive mode where Keyboard listens keyup/keydown and emits pressed keys
      handleTyping: {
        type: Boolean,
        default: false,
      },

      // Character to type for key highlighting
      charToType: {
        type: String,
        required: false,
      },

      // Selected layout simulation, no matter what is using in the user's system now
      simulateLayout: {
        type: Boolean,
        default: false,
      },
    },

    emits: [
      'correctPress',   // match 'charToType', returns PressObject
      'wrongPress',     // mismatch 'charToType', returns PressObject
      'press',          // any keydown, returns PressObject

      'layoutMismatch', // system layout is different then rendered one
    ],

    setup(props, { attrs, slots, emit }) {
      const store = useStore();

      const keyboard = ref<KeyboardLayout | null>(
        KeyboardLayouts[props.layoutProps.language].defineByProps(props.layoutProps),
      );
      if (!keyboard.value) {
        throw new Error('Undefined keyboard layout!');
      }

      // What and how to type next? – key and hands position
      const toPressPrev = ref<NextPosition>();
      const toPressNext = computed(() => {
        if (!props.charToType) {
          return null;
        }
        const next = nextPosition(props.charToType, keyboard.value as KeyboardLayout, toPressPrev.value);
        // toPressPrev.value = next;

        return next;
      });

      // Pressed and not released keys
      enum KeyStatus {
        Correct = 'correct',
        Wrong = 'wrong',
        Pressed = 'pressed',
      }
      const pressedKeys = ref<Map<KeyCode<null>, KeyStatus>>(new Map());
      const hands = computed(() => {
        if (props.showHands) {
          if (toPressNext.value) {
            return toPressNext.value.hands;
          }
          return getStartHandsProps(keyboard.value as KeyboardLayout);
        }
        return null;
      });

      // Handle typing

      function handleKeyDown(e: KeyboardEvent): void {
        // Avoid keys sticking
        if ((e.ctrlKey && e.key !== 'Control')
          || (e.altKey && e.key !== 'Alt')
          || (e.metaKey && e.key !== 'Meta')) {
          return;
        }

        if (!keyboard.value) throw new Error('Undefined keyboard layout!');

        // Do not scroll down on Space press
        e.preventDefault();

        const pressObject: PressObject = {
          code: codeProxy(e.code as KeyCode<null>, window.navigator, keyboard.value.props.format),
          key: e.key,
        };

        if (props.simulateLayout) {
          const layoutKey = keyboard.value.getAllKeys().get(pressObject.code);
          if (layoutKey) {
            pressObject.key = layoutKey.char(e.shiftKey);
          }
        }

        // No Ё in Apple RussianPC ANSI layout
        const isANSI = keyboard.value.props.format === Formats.ANSI;
        if (isANSI && keyboard.value.props.name === KeyboardLayouts.ru.names.RussianApplePC) {
          if (pressObject.key === 'е' && props.charToType === 'ё') {
            pressObject.key = 'ё';
          } else if (pressObject.key === 'Е' && props.charToType === 'Ё') {
            pressObject.key = 'Ё';
          }
        }

        emit('press', pressObject);

        if (!props.charToType) {
          pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          return;
        }

        if (pressObject.code === toPressNext.value?.code || toPressNext.value?.shift) {
          if (pressObject.key === props.charToType) {
            emit('correctPress', pressObject);
            pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          } else if (pressObject.code === 'Backspace' && props.charToType === 'Backspace') {
            emit('correctPress');
            pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          } else if (
            (pressObject.code === 'ShiftLeft' && toPressNext.value?.shift === Position.Left)
            || (pressObject.code === 'ShiftRight' && toPressNext.value?.shift === Position.Right)
          ) {
            emit('correctPress', pressObject);
            pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          } else if (!pressedKeys.value.get(pressObject.code)) {
            emit('wrongPress', pressObject);
            pressedKeys.value.set(pressObject.code, KeyStatus.Wrong);
            emit('layoutMismatch');
          }
        } else if (!pressedKeys.value.get(pressObject.code)) {
          emit('wrongPress', pressObject);
          pressedKeys.value.set(pressObject.code, KeyStatus.Wrong);
        }
      }

      const handleKeyUp = (e: KeyboardEvent): void => {
        if (!keyboard.value) throw new Error('Undefined keyboard layout!');

        const code = codeProxy(e.code as KeyCode<null>, window.navigator, keyboard.value.props.format);
        pressedKeys.value.delete(code);
      };

      // Dynamic keyboard size

      const keyboardWrapper: Ref<null | HTMLElement> = ref(null);

      function setUnit() {
        const UNIT_FACTOR = 154.5;

        if (!keyboardWrapper.value || !keyboardWrapper.value) {
          return;
        }

        let keyboardWidth;
        if (keyboardWrapper.value) {
          keyboardWidth = window.getComputedStyle(keyboardWrapper.value, null).getPropertyValue('width');
          const unit = Number.parseFloat(keyboardWidth) / UNIT_FACTOR;

          (document.querySelector('body') as HTMLBodyElement).style.setProperty('--keyboard-size-unit', `${unit}px`);
        }
      }

      store.commit('windowResize/addHandler', { handler: setUnit });

      onMounted(() => {
        if (props.handleTyping) {
          document.addEventListener('keydown', handleKeyDown);
          document.addEventListener('keyup', handleKeyUp);
        }

        setUnit();
      });

      onUnmounted(() => {
        if (props.handleTyping) {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('keyup', handleKeyUp);
        }
      });

      watchEffect(() => {
        if (props.handleTyping) {
          document.addEventListener('keydown', handleKeyDown);
          document.addEventListener('keyup', handleKeyUp);
        } else {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('keyup', handleKeyUp);
        }
      });

      return {
        Formats, keyboard, CharType, toPressNext, hands, pressedKeys, keyboardWrapper,
      };
    },
  });
