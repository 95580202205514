/* eslint-disable no-multi-spaces */
import {
  Formats, Languages, System, Format, LayoutProps, Systems,
} from '../dataTypes';
import {
  CharType as t, Key,
} from  '../key';
import {
  KeyboardLayout,
} from '../keyboardLayout';

const names = {
  Qwerty: 'qwerty',
  Dvorak: 'dvorak',
  Colemak: 'colemak',
};

const layouts: KeyboardLayout[] = [];

// Qwerty

layouts.push(new KeyboardLayout(
  {
    system: 'ANY',
    format: Formats.ANSI,
    language: Languages.EN,
    name: names.Qwerty,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyW',          new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['KeyE',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyR',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyT',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyY',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyU',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyI',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['KeyO',          new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['KeyP',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['BracketLeft',   new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['BracketRight',  new Key([t.Symbol, ']'], [t.Symbol, '}'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['KeyD',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyF',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyG',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyH',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyJ',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyK',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyL',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['Semicolon',     new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['Quote',         new Key([t.Symbol, '\''], [t.Symbol, '"'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
      ['KeyX',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyC',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyV',          new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['KeyB',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyN',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['Period',        new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ISO,
    language: Languages.EN,
    name: names.Qwerty,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyW',          new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['KeyE',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyR',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyT',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyY',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyU',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyI',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['KeyO',          new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['KeyP',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['BracketLeft',   new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['BracketRight',  new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['KeyD',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyF',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyG',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyH',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyJ',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyK',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyL',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['Semicolon',     new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['Quote',         new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
      ['KeyZ',          new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
      ['KeyX',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyC',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyV',          new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['KeyB',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyN',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['Period',        new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ISO,
    language: Languages.EN,
    name: names.Qwerty,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '§'], [t.Symbol, '±'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyW',          new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['KeyE',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyR',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyT',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyY',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyU',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyI',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['KeyO',          new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['KeyP',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['BracketLeft',   new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['BracketRight',  new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['KeyD',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyF',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyG',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyH',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyJ',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyK',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyL',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['Semicolon',     new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['Quote',         new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['KeyZ',          new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
      ['KeyX',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyC',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyV',          new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['KeyB',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyN',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['Period',        new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

// // Dvorak

layouts.push(new KeyboardLayout(
  {
    system: 'ANY',
    format: Formats.ANSI,
    language: Languages.EN,
    name: names.Dvorak,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['Equal',         new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['KeyW',          new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['KeyE',          new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['KeyR',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['KeyT',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyY',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyU',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyI',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyO',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyP',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['BracketLeft',   new Key([t.Symbol, '/'], [t.Symbol, '?'])],
      ['BracketRight',  new Key([t.Symbol, '='], [t.Symbol, '+'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['KeyD',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyF',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyG',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['KeyH',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyJ',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyK',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyL',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['Semicolon',     new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['Quote',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['KeyX',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyC',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyV',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyB',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyN',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['Period',        new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['Slash',         new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ISO,
    language: Languages.EN,
    name: names.Dvorak,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['Equal',         new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['KeyW',          new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['KeyE',          new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['KeyR',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['KeyT',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyY',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyU',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyI',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyO',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyP',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['BracketLeft',   new Key([t.Symbol, '/'], [t.Symbol, '?'])],
      ['BracketRight',  new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['KeyD',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyF',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyG',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['KeyH',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyJ',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyK',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyL',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['Semicolon',     new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['Quote',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
      ['KeyZ',          new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['KeyX',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyC',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyV',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyB',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyN',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['Period',        new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['Slash',         new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ISO,
    language: Languages.EN,
    name: names.Dvorak,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '§'], [t.Symbol, '±'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['Equal',         new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['KeyW',          new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['KeyE',          new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['KeyR',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['KeyT',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyY',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyU',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyI',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyO',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyP',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['BracketLeft',   new Key([t.Symbol, '/'], [t.Symbol, '?'])],
      ['BracketRight',  new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['KeyD',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyF',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyG',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['KeyH',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyJ',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyK',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyL',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['Semicolon',     new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['Quote',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['KeyZ',          new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['KeyX',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyC',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyV',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyB',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyN',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['Period',        new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['Slash',         new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
    ]),
  ],
));

// Colemak

layouts.push(new KeyboardLayout(
  {
    system: 'ANY',
    format: Formats.ANSI,
    language: Languages.EN,
    name: names.Colemak,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyW',          new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['KeyE',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyR',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['KeyT',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyY',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyU',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['KeyI',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyO',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyP',          new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['BracketLeft',   new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['BracketRight',  new Key([t.Symbol, ']'], [t.Symbol, '}'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyD',          new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['KeyF',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyG',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyH',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyJ',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['KeyK',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyL',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['Semicolon',     new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['Quote',         new Key([t.Symbol, '\''], [t.Symbol, '"'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
      ['KeyX',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyC',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyV',          new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['KeyB',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyN',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['Period',        new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ISO,
    language: Languages.EN,
    name: names.Colemak,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyW',          new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['KeyE',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyR',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['KeyT',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyY',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyU',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['KeyI',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyO',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyP',          new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['BracketLeft',   new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['BracketRight',  new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyD',          new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['KeyF',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyG',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyH',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyJ',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['KeyK',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyL',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['Semicolon',     new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['Quote',         new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
      ['KeyZ',          new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
      ['KeyX',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyC',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyV',          new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['KeyB',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyN',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['Period',        new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ISO,
    language: Languages.EN,
    name: names.Colemak,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '§'], [t.Symbol, '±'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '@'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '#'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '$'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, '^'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '&'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'q'], [t.Letter, 'Q'])],
      ['KeyW',          new Key([t.Letter, 'w'], [t.Letter, 'W'])],
      ['KeyE',          new Key([t.Letter, 'f'], [t.Letter, 'F'])],
      ['KeyR',          new Key([t.Letter, 'p'], [t.Letter, 'P'])],
      ['KeyT',          new Key([t.Letter, 'g'], [t.Letter, 'G'])],
      ['KeyY',          new Key([t.Letter, 'j'], [t.Letter, 'J'])],
      ['KeyU',          new Key([t.Letter, 'l'], [t.Letter, 'L'])],
      ['KeyI',          new Key([t.Letter, 'u'], [t.Letter, 'U'])],
      ['KeyO',          new Key([t.Letter, 'y'], [t.Letter, 'Y'])],
      ['KeyP',          new Key([t.Symbol, ';'], [t.Symbol, ':'])],
      ['BracketLeft',   new Key([t.Symbol, '['], [t.Symbol, '{'])],
      ['BracketRight',  new Key([t.Symbol, ']'], [t.Symbol, '}'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'a'], [t.Letter, 'A'])],
      ['KeyS',          new Key([t.Letter, 'r'], [t.Letter, 'R'])],
      ['KeyD',          new Key([t.Letter, 's'], [t.Letter, 'S'])],
      ['KeyF',          new Key([t.Letter, 't'], [t.Letter, 'T'])],
      ['KeyG',          new Key([t.Letter, 'd'], [t.Letter, 'D'])],
      ['KeyH',          new Key([t.Letter, 'h'], [t.Letter, 'H'])],
      ['KeyJ',          new Key([t.Letter, 'n'], [t.Letter, 'N'])],
      ['KeyK',          new Key([t.Letter, 'e'], [t.Letter, 'E'])],
      ['KeyL',          new Key([t.Letter, 'i'], [t.Letter, 'I'])],
      ['Semicolon',     new Key([t.Letter, 'o'], [t.Letter, 'O'])],
      ['Quote',         new Key([t.Symbol, '\''], [t.Symbol, '"'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '|'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '`'], [t.Symbol, '~'])],
      ['KeyZ',          new Key([t.Letter, 'z'], [t.Letter, 'Z'])],
      ['KeyX',          new Key([t.Letter, 'x'], [t.Letter, 'X'])],
      ['KeyC',          new Key([t.Letter, 'c'], [t.Letter, 'C'])],
      ['KeyV',          new Key([t.Letter, 'v'], [t.Letter, 'V'])],
      ['KeyB',          new Key([t.Letter, 'b'], [t.Letter, 'B'])],
      ['KeyN',          new Key([t.Letter, 'k'], [t.Letter, 'K'])],
      ['KeyM',          new Key([t.Letter, 'm'], [t.Letter, 'M'])],
      ['Comma',         new Key([t.Symbol, ','], [t.Symbol, '<'])],
      ['Period',        new Key([t.Symbol, '.'], [t.Symbol, '>'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

const defineByProps = (props: LayoutProps): KeyboardLayout | null => {
  for (let layoutIx = 0; layoutIx < layouts.length; layoutIx += 1) {
    const layout = layouts[layoutIx];

    if (layout.isEqual(props)) {
      return layout;
    }
  }

  return null;
};

const defineByKeyPress = (event: KeyboardEvent, system: System, format: Format): KeyboardLayout | null => {
  const props = {
    system,
    format,
    language: Languages.EN,
    name: '',
  };

  if (event.key === 'k') {
    switch (event.code) {
      case 'KeyK':
        props.name = names.Qwerty;
        break;
      case 'KeyV':
        props.name = names.Dvorak;
        break;
      case 'KeyN':
        props.name = names.Colemak;
        break;
      default:
        break;
    }
  }

  return defineByProps(props);
};

export {
  layouts, names, defineByKeyPress, defineByProps,
};
