<template>
  <section
    ref="keyboardWrapper"
    class="keyboard"
  >
    <div
      v-if="keyboard.keys"
      class="keyboard-wrapper"
      :class="[size, showHands ? 'width-hands' : '']"
    >
      <div class="keys">
        <div class="replaceable-keys">
          <div
            v-for="(row, i) in keyboard.keys"
            :key="i"
            class="row"
          >
            <!-- Start of row -->
            <div
              v-if="i === 1"
              class="key tab"
              :class="pressedKeys.get('Tab')"
            >
              <svg
                class="icon tab"
                width="21"
                height="11"
                viewBox="0 0 21 11"
              >
                <use href="#icon-tab" />
              </svg>
            </div>
            <div
              v-if="i === 2"
              class="key capslock"
              :class="pressedKeys.get('CapsLock')"
            >
              <svg
                class="icon capslock"
                width="16"
                height="22"
                viewBox="0 0 16 22"
              >
                <use href="#icon-capslock" />
              </svg>
            </div>
            <div
              v-if="i === 3"
              class="key"
              :class="[
                keyboard.props.format === Formats.ISO ? 'short-left-shift' : 'left-shift',
                pressedKeys.get('ShiftLeft'),
                toPressNext?.shift === 'left' ? 'required' : '',
              ]"
            >
              <svg class="icon shift" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <use href="#icon-shift"></use>
              </svg>
            </div>

            <!-- List of layout keys -->
            <div
              v-for="[code, key] in row"
              :key="code"
              class="key"
              :class="[
                pressedKeys.get(code),
                toPressNext?.code === code ? 'required' : '']"
            >
              <div class="key-text">
                {{ key.shift.value }}
              </div>
              <div
                v-if="!key.isLetter()"
                class="key-text"
              >
                {{ key.default.value }}
              </div>
            </div>

            <!-- End of row -->
            <div
              v-if="i === 0"
              class="key backspace"
              :class="[
                pressedKeys.get('Backspace'),
                toPressNext?.code === 'Backspace' ? 'required' : '']"
            >
              <svg
                class="icon backspace"
                width="21"
                height="11"
                viewBox="0 0 21 11"
              >
                <use href="#icon-backspace" />
              </svg>
            </div>
            <div
              v-if="i === 1 && keyboard.props.format === Formats.ISO"
              class="key tall-enter"
              :class="[
                pressedKeys.get('Enter'),
                toPressNext?.code === 'Enter' ? 'required' : '']"
            >
              <svg
                class="icon enter"
                width="21"
                height="16"
                viewBox="0 0 21 16"
              >
                <use href="#icon-enter" />
              </svg>
            </div>
            <div
              v-if="i === 2 && keyboard.props.format === Formats.ANSI"
              class="key enter"
              :class="[
                pressedKeys.get('Enter'),
                toPressNext?.code === 'Enter' ? 'required' : '']"
            >
              <svg
                class="icon enter"
                width="21"
                height="16"
              >
                <use href="#icon-enter" />
              </svg>
            </div>
            <div
              v-if="i === 3"
              class="key right-shift"
              :class="[
                toPressNext?.shift === 'right' ? 'required' : '',
                pressedKeys.get('ShiftRight')
              ]"
            >
              <svg
                class="icon shift"
                width="16"
                height="16"
              >
                <use href="#icon-shift" />
              </svg>
            </div>
          </div>
        </div>
        <div class="permanent-keys">
          <div class="row">
            <div
              class="key control"
              :class="pressedKeys.get('ControlLeft')"
            >
              <div class="key-text">
                Control
              </div>
            </div>
            <div
              class="key alt"
              :class="pressedKeys.get('AltLeft')"
            >
              <div class="key-text">
                Alt
              </div>
            </div>
            <div
              class="key space"
              :class="[
                pressedKeys.get('Space'),
                toPressNext?.code === 'Space' ? 'required' : '']"
            />
            <div
              class="key alt"
              :class="pressedKeys.get('AltRight')"
            >
              <div class="key-text">
                Alt
              </div>
            </div>

            <div class="arrows">
              <div
                class="key left-arrow"
                :class="pressedKeys.get('ArrowLeft')"
              >
                <svg
                  class="icon arrow"
                  width="6"
                  height="7"
                >
                  <use href="#icon-key-arrow" />
                </svg>
              </div>
              <div
                class="key top-arrow"
                :class="pressedKeys.get('ArrowUp')"
              >
                <svg
                  class="icon arrow"
                  width="6"
                  height="7"
                >
                  <use href="#icon-key-arrow" />
                </svg>
              </div>
              <div
                class="key bottom-arrow"
                :class="pressedKeys.get('ArrowDown')"
              >
                <svg
                  class="icon arrow"
                  width="6"
                  height="7"
                >
                  <use href="#icon-key-arrow" />
                </svg>
              </div>
              <div
                class="key right-arrow"
                :class="pressedKeys.get('ArrowRight')"
              >
                <svg
                  class="icon arrow"
                  width="6"
                  height="7"
                >
                  <use href="#icon-key-arrow" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <!-- SVG for some keys -->
        <template>
          <svg xmlns="http://www.w3.org/2000/svg">
            <symbol id="icon-backspace" viewBox="0 0 21 11">
              <path fill="currentColor" d="M8.94.75a.5.5 0 01-.12.63l-.07.06L2.4 5H20a.5.5 0 01.09 1H2.4l6.35 3.56c.2.12.3.38.22.6l-.03.09a.5.5 0 01-.6.22l-.09-.03-8-4.5a.5.5 0 01-.07-.82l.07-.06 8-4.5a.5.5 0 01.69.2z"/>
            </symbol>
            <symbol id="icon-tab" viewBox="0 0 21 11">
              <path fill="currentColor" fill-rule="evenodd" d="M21 .5a.5.5 0 00-1 0v10a.5.5 0 001 0V.5zM9.75.56L9.66.53a.5.5 0 00-.6.22l-.03.09a.5.5 0 00.22.6L15.6 5H.91A.5.5 0 001 6h14.6L9.24 9.56l-.07.06a.5.5 0 00.57.82l8-4.5.07-.06a.5.5 0 00-.07-.82l-8-4.5z" clip-rule="evenodd"/>
            </symbol>
            <symbol id="icon-enter" viewBox="0 0 21 16">
              <path fill="currentColor" d="M18 .5a2.5 2.5 0 012.5 2.34V9a2.5 2.5 0 01-2.34 2.5H2.4l6.35 3.56c.2.12.3.38.22.6l-.03.09a.5.5 0 01-.6.22l-.09-.03-8-4.5a.5.5 0 01-.07-.82l.07-.06 8-4.5a.5.5 0 01.57.82l-.07.06L2.4 10.5H18c.78 0 1.42-.6 1.5-1.36V3c0-.78-.6-1.42-1.36-1.5h-2.56a.5.5 0 01-.1-1H18z"/>
            </symbol>
            <symbol id="icon-capslock" viewBox="0 0 16 22" fill="none">
              <path stroke="currentColor" d="M5.4 15h5.12a1 1 0 001-1v-3.27a1 1 0 011-1h1.24a.99.99 0 00.73-1.65L8.74 1.8a1 1 0 00-1.48 0L1.5 8.1a.97.97 0 00.7 1.63h1.2a1 1 0 011 1V14a1 1 0 001 1zM10 17H6a1 1 0 00-1 1v2a1 1 0 001 1h4a1 1 0 001-1v-2a1 1 0 00-1-1z" clip-rule="evenodd"/>
            </symbol>
            <symbol id="icon-shift" viewBox="0 0 16 16" fill="none">
              <path stroke="currentColor" d="M5.17 14.52h5.12a1 1 0 001-1v-3.27a1 1 0 011-1h1.24a.99.99 0 00.72-1.65L8.51 1.32a1 1 0 00-1.48 0l-5.78 6.3a.97.97 0 00.72 1.63h1.2a1 1 0 011 1v3.27a1 1 0 001 1z" clip-rule="evenodd"/>
            </symbol>
            <symbol id="icon-key-arrow" viewBox="0 0 6 7">
              <path fill="currentColor" fill-rule="evenodd" d="M0 .5v5.26a.5.5 0 00.75.43l4.51-2.63a.5.5 0 000-.86L.76.07A.5.5 0 000 .5z" clip-rule="evenodd"/>
            </symbol>
          </svg>
        </template>
      </div>

      <template v-if="hands">
        <!-- Left hand -->
        <svg
          class="hand left"
          :style="{
            'transform': `translate(${hands.left.offset.x}%, ${hands.left.offset.y}%)`
          }"
        ><use :href="'#' + hands.left.id" /></svg>

        <!-- Right hand -->
        <svg
          class="hand right"
          :style="{
            'transform': `translate(${hands.right.offset.x}%, ${hands.right.offset.y}%)`
          }"
        ><use :href="'#' + hands.right.id" /></svg>

        <!-- SVG sprite -->
        <HandsSVG />
      </template>
    </div>
  </section>
</template>

<script lang="ts">
  import {
    computed, ref, defineComponent, onUnmounted, onMounted, PropType, watchEffect, Ref,
  } from 'vue';
  import { useStore } from 'vuex';
  import HandsSVG from '@/components/HandsSVG.vue';

  import { codeProxy } from '@/utils';
  import {
    KeyCode, Formats, LayoutProps, PressObject,
  } from '@/config/keyboards/dataTypes';
  import {
    KeyboardLayout,
  } from '@/config/keyboards/keyboardLayout';
  import {
    CharType,
  } from '@/config/keyboards/key';
  import { layouts as KeyboardLayouts } from '@/config/keyboards/layoutsAll';
  import {
    nextPosition, NextPosition, getStartHandsProps, Position,
  } from './utils';

  export default defineComponent({
    name: 'Keyboard',

    components: {
      HandsSVG,
    },

    props: {
      // Layout to display
      layoutProps: {
        type: Object as PropType<LayoutProps>,
        required: true,
      },

      // 'auto' will resize by Trainer breakpoints
      size: {
        type: String as PropType<'small' | 'medium' | 'large' | 'auto' | 'fit-profile'>,
        default: 'auto',
      },

      showHands: {
        type: Boolean,
        default: false,
      },

      // Interactive mode where Keyboard listens keyup/keydown and emits pressed keys
      handleTyping: {
        type: Boolean,
        default: false,
      },

      // Character to type for key highlighting
      charToType: {
        type: String,
        required: false,
      },

      // Selected layout simulation, no matter what is using in the user's system now
      simulateLayout: {
        type: Boolean,
        default: false,
      },
    },

    emits: [
      'correctPress',   // match 'charToType', returns PressObject
      'wrongPress',     // mismatch 'charToType', returns PressObject
      'press',          // any keydown, returns PressObject

      'layoutMismatch', // system layout is different then rendered one
    ],

    setup(props, { attrs, slots, emit }) {
      const store = useStore();

      const keyboard = ref<KeyboardLayout | null>(
        KeyboardLayouts[props.layoutProps.language].defineByProps(props.layoutProps),
      );
      if (!keyboard.value) {
        throw new Error('Undefined keyboard layout!');
      }

      // What and how to type next? – key and hands position
      const toPressPrev = ref<NextPosition>();
      const toPressNext = computed(() => {
        if (!props.charToType) {
          return null;
        }
        const next = nextPosition(props.charToType, keyboard.value as KeyboardLayout, toPressPrev.value);
        // toPressPrev.value = next;

        return next;
      });

      // Pressed and not released keys
      enum KeyStatus {
        Correct = 'correct',
        Wrong = 'wrong',
        Pressed = 'pressed',
      }
      const pressedKeys = ref<Map<KeyCode<null>, KeyStatus>>(new Map());
      const hands = computed(() => {
        if (props.showHands) {
          if (toPressNext.value) {
            return toPressNext.value.hands;
          }
          return getStartHandsProps(keyboard.value as KeyboardLayout);
        }
        return null;
      });

      // Handle typing

      function handleKeyDown(e: KeyboardEvent): void {
        // Avoid keys sticking
        if ((e.ctrlKey && e.key !== 'Control')
          || (e.altKey && e.key !== 'Alt')
          || (e.metaKey && e.key !== 'Meta')) {
          return;
        }

        if (!keyboard.value) throw new Error('Undefined keyboard layout!');

        // Do not scroll down on Space press
        e.preventDefault();

        const pressObject: PressObject = {
          code: codeProxy(e.code as KeyCode<null>, window.navigator, keyboard.value.props.format),
          key: e.key,
        };

        if (props.simulateLayout) {
          const layoutKey = keyboard.value.getAllKeys().get(pressObject.code);
          if (layoutKey) {
            pressObject.key = layoutKey.char(e.shiftKey);
          }
        }

        // No Ё in Apple RussianPC ANSI layout
        const isANSI = keyboard.value.props.format === Formats.ANSI;
        if (isANSI && keyboard.value.props.name === KeyboardLayouts.ru.names.RussianApplePC) {
          if (pressObject.key === 'е' && props.charToType === 'ё') {
            pressObject.key = 'ё';
          } else if (pressObject.key === 'Е' && props.charToType === 'Ё') {
            pressObject.key = 'Ё';
          }
        }

        emit('press', pressObject);

        if (!props.charToType) {
          pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          return;
        }

        if (pressObject.code === toPressNext.value?.code || toPressNext.value?.shift) {
          if (pressObject.key === props.charToType) {
            emit('correctPress', pressObject);
            pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          } else if (pressObject.code === 'Backspace' && props.charToType === 'Backspace') {
            emit('correctPress');
            pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          } else if (
            (pressObject.code === 'ShiftLeft' && toPressNext.value?.shift === Position.Left)
            || (pressObject.code === 'ShiftRight' && toPressNext.value?.shift === Position.Right)
          ) {
            emit('correctPress', pressObject);
            pressedKeys.value.set(pressObject.code, KeyStatus.Pressed);
          } else if (!pressedKeys.value.get(pressObject.code)) {
            emit('wrongPress', pressObject);
            pressedKeys.value.set(pressObject.code, KeyStatus.Wrong);
            emit('layoutMismatch');
          }
        } else if (!pressedKeys.value.get(pressObject.code)) {
          emit('wrongPress', pressObject);
          pressedKeys.value.set(pressObject.code, KeyStatus.Wrong);
        }
      }

      const handleKeyUp = (e: KeyboardEvent): void => {
        if (!keyboard.value) throw new Error('Undefined keyboard layout!');

        const code = codeProxy(e.code as KeyCode<null>, window.navigator, keyboard.value.props.format);
        pressedKeys.value.delete(code);
      };

      // Dynamic keyboard size

      const keyboardWrapper: Ref<null | HTMLElement> = ref(null);

      function setUnit() {
        const UNIT_FACTOR = 154.5;

        if (!keyboardWrapper.value || !keyboardWrapper.value) {
          return;
        }

        let keyboardWidth;
        if (keyboardWrapper.value) {
          keyboardWidth = window.getComputedStyle(keyboardWrapper.value, null).getPropertyValue('width');
          const unit = Number.parseFloat(keyboardWidth) / UNIT_FACTOR;

          (document.querySelector('body') as HTMLBodyElement).style.setProperty('--keyboard-size-unit', `${unit}px`);
        }
      }

      store.commit('windowResize/addHandler', { handler: setUnit });

      onMounted(() => {
        if (props.handleTyping) {
          document.addEventListener('keydown', handleKeyDown);
          document.addEventListener('keyup', handleKeyUp);
        }

        setUnit();
      });

      onUnmounted(() => {
        if (props.handleTyping) {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('keyup', handleKeyUp);
        }
      });

      watchEffect(() => {
        if (props.handleTyping) {
          document.addEventListener('keydown', handleKeyDown);
          document.addEventListener('keyup', handleKeyUp);
        } else {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('keyup', handleKeyUp);
        }
      });

      return {
        Formats, keyboard, CharType, toPressNext, hands, pressedKeys, keyboardWrapper,
      };
    },
  });
</script>

<style lang="scss" scoped>
.keyboard {
  width: 100%;
  text-align: center;
  font-size: 0;
}

.keyboard-wrapper {
  display: inline-block;
  position: relative;
  font-weight: 400;
  color: rgb(var(--color-keyboard-key-text));
  overflow: hidden;

  .keys {
    display: grid;
    background-image:
      linear-gradient(to bottom, rgb(var(--color-keyboard-gradient-from)) 0%,
      rgb(var(--color-keyboard-gradient-to)) 100%);
  }

  .replaceable-keys {
    display: grid;
  }

  .row {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: end;
  }

  .arrows {
    display: grid;
    grid-template-areas: "left-arrow top-arrow right-arrow" "left-arrow bottom-arrow right-arrow";
    align-items: flex-end;
  }

  .key {
    display: grid;
    justify-items: center;
    align-content: space-around;
    border: 1px solid rgb(var(--color-keyboard-key-border));
    background-image:
      linear-gradient(to bottom, rgb(var(--color-keyboard-key-gradient-from)) 0%,
      rgb(var(--color-keyboard-key-gradient-to)) 100%);

    &.required,
    &.correct,
    &.wrong {
      color: rgb(var(--color-keyboard-key-text-on-active));
      background-image: none;

      &.tall-enter {
        &:after {
          background-image: none;
        }
      }
    }

    &.required {
      background-color: rgb(var(--color-keyboard-key-bg-to-press));

      &.tall-enter:before,
      &.tall-enter:after {
        background-color: rgb(var(--color-keyboard-key-bg-to-press));
      }
    }

    &.correct {
      background-color: rgb(var(--color-keyboard-key-bg-correct));

      &.tall-enter:before,
      &.tall-enter:after {
        background-color: rgb(var(--color-keyboard-key-bg-correct));
      }
    }

    &.wrong {
      background-color: rgb(var(--color-keyboard-key-bg-wrong));

      &.tall-enter:before,
      &.tall-enter:after {
        background-color: rgb(var(--color-keyboard-key-bg-wrong));
      }
    }

    &.backspace,
    &.tab,
    &.enter,
    &.tall-enter,
    &.capslock,
    &.left-shift,
    &.short-left-shift,
    &.right-shift,
    &.control,
    &.alt {
      align-content: end;
    }

    &.tab,
    &.capslock,
    &.left-shift,
    &.short-left-shift,
    &.control,
    &.alt {
      justify-items: start;
    }

    &.backspace,
    &.enter,
    &.tall-enter,
    &.right-shift {
      justify-items: end;
    }

    &.tall-enter {
      position: relative;
      box-shadow: none;

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        top: 100%;
        right: -1px;
        border: 1px solid rgb(var(--color-keyboard-key-border));
        border-top: none;
        background-color: rgb(var(--color-keyboard-key-gradient-to));
      }

      &::after {
        top: 0;
        right: 0;
        background-image:
          linear-gradient(to bottom, rgb(var(--color-keyboard-key-gradient-from)) 0%,
          rgb(var(--color-keyboard-key-gradient-to)) 100%);
      }
    }

    &.top-arrow {
      grid-area: top-arrow;
      margin-bottom: -1px;

      .arrow {
        transform: rotate(-90deg);
      }
    }

    &.right-arrow {
      grid-area: right-arrow;
    }

    &.bottom-arrow {
      grid-area: bottom-arrow;

      .arrow {
        transform: rotate(90deg);
      }
    }

    &.left-arrow {
      grid-area: left-arrow;

      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  .hand {
    position: absolute;
    opacity: 0.6;
  }

  font-size: calc(var(--keyboard-size-unit, 3.5px) * 2.5);
  line-height: calc(var(--keyboard-size-unit, 3.5px) * 2.5);

  &.width-hands {
    padding-bottom: calc(var(--keyboard-size-unit, 3.5px) * 10);
  }

  .keys {
    row-gap: calc(var(--keyboard-size-unit, 3.5px) * 1.5);
    border-radius: calc(var(--keyboard-size-unit, 3.5px) * 2);
    padding: calc(var(--keyboard-size-unit, 3.5px) * 1.5);
    padding-bottom: calc(var(--keyboard-size-unit, 3.5px) * 2.5);
    box-shadow: inset 0 calc(var(--keyboard-size-unit, 3.5px) * -1) rgb(var(--color-keyboard-bottom-shadow));
  }

  .replaceable-keys {
    row-gap: calc(var(--keyboard-size-unit, 3.5px) * 1.5);
  }

  .row {
    column-gap: calc(var(--keyboard-size-unit, 3.5px) * 1.5);
  }

  .arrows {
    column-gap: calc(var(--keyboard-size-unit, 3.5px) * 1.5);
  }

  .key {
    width: calc(var(--keyboard-size-unit, 3.5px) * 9);
    height: calc(var(--keyboard-size-unit, 3.5px) * 9);
    border-radius: var(--keyboard-size-unit, 3.5px);
    padding: var(--keyboard-size-unit, 3.5px) calc(var(--keyboard-size-unit, 3.5px) * 1.5);
    box-shadow: inset 0 calc(var(--keyboard-size-unit, 3.5px) * -0.4) rgb(var(--color-keyboard-key-shadow));

    &.required {
      box-shadow: inset 0 calc(var(--keyboard-size-unit, 3.5px) * -0.5) rgb(var(--color-keyboard-key-to-press-shadow));

      &.tall-enter:before {
        box-shadow:
          inset 0 calc(var(--keyboard-size-unit, 3.5px) * -0.5) rgb(var(--color-keyboard-key-to-press-shadow));
      }
    }

    &.correct,
    &.wrong,
    &.pressed {
      height: calc(var(--keyboard-size-unit, 3.5px) * 8.5);
      padding-bottom: calc(var(--keyboard-size-unit, 3.5px) * 0.5);
      box-shadow: none;

      &.tall-enter:before {
        box-shadow: none;
      }
    }

    &.backspace,
    &.tab,
    &.enter,
    &.tall-enter,
    &.capslock,
    &.left-shift,
    &.short-left-shift,
    &.right-shift,
    &.control,
    &.alt {
      padding-bottom: calc(var(--keyboard-size-unit, 3.5px) * 1.5);

      &.correct,
      &.wrong,
      &.pressed {
        padding-bottom: var(--keyboard-size-unit, 3.5px);
      }
    }

    &.backspace,
    &.tab,
    &.control,
    &.alt {
      width: calc(var(--keyboard-size-unit, 3.5px) * 15);
    }

    &.left-shift,
    &.right-shift {
      width: calc(var(--keyboard-size-unit, 3.5px) * 22.5);
    }

    &.control,
    &.alt {
      font-size: calc(var(--keyboard-size-unit, 3.5px) * 1.75);
      line-height: calc(var(--keyboard-size-unit, 3.5px) * 1.75);
    }

    &.enter {
      width: calc(var(--keyboard-size-unit, 3.5px) * 17.5);
    }

    &.tall-enter {
      border-bottom-right-radius: 0;

      &::before {
        width: calc(var(--keyboard-size-unit, 3.5px) * 7);
        height: calc(var(--keyboard-size-unit, 3.5px) * 10.5 + 1px);
        border-bottom-right-radius: var(--keyboard-size-unit, 3.5px);
        border-bottom-left-radius: var(--keyboard-size-unit, 3.5px);
        box-shadow: inset 0 calc(var(--keyboard-size-unit, 3.5px) * -0.5) rgb(var(--color-keyboard-key-shadow));
      }

      &::after {
        width: calc(var(--keyboard-size-unit, 3.5px) * 7 - 2px);
        height: calc(var(--keyboard-size-unit, 3.5px) * 9);
        border-top-right-radius: var(--keyboard-size-unit, 3.5px);
      }
    }

    &.capslock {
      width: calc(var(--keyboard-size-unit, 3.5px) * 17);
    }

    &.short-left-shift {
      width: calc(var(--keyboard-size-unit, 3.5px) * 12);
    }

    &.space {
      width: calc(var(--keyboard-size-unit, 3.5px) * 70.5);
    }

    &.top-arrow {
      height: calc(var(--keyboard-size-unit, 3.5px) * 4.5 + 1px);
      border-bottom-right-radius: calc(var(--keyboard-size-unit, 3.5px) * 0.5);
      border-bottom-left-radius: calc(var(--keyboard-size-unit, 3.5px) * 0.5);
    }

    &.bottom-arrow {
      height: calc(var(--keyboard-size-unit, 3.5px) * 4.5);
      border-top-right-radius: calc(var(--keyboard-size-unit, 3.5px) * 0.5);
      border-top-left-radius: calc(var(--keyboard-size-unit, 3.5px) * 0.5);
    }
  }

  .icon {
    &.backspace,
    &.tab {
      width: calc(var(--keyboard-size-unit, 3.5px) * 3.5);
      height: calc(var(--keyboard-size-unit, 3.5px) * 1.75);
    }

    &.enter {
      z-index: 1;
      width: calc(var(--keyboard-size-unit, 3.5px) * 3.5);
      height: calc(var(--keyboard-size-unit, 3.5px) * 2.5);
    }

    &.capslock {
      width: calc(var(--keyboard-size-unit, 3.5px) * 2.5);
      height: calc(var(--keyboard-size-unit, 3.5px) * 3.5);
    }

    &.shift {
      width: calc(var(--keyboard-size-unit, 3.5px) * 2.5);
      height: calc(var(--keyboard-size-unit, 3.5px) * 2.5);
    }

    &.arrow {
      width: calc(var(--keyboard-size-unit, 3.5px) * 1);
      height: calc(var(--keyboard-size-unit, 3.5px) * 1);
    }
  }

  .hand {
    top: calc(var(--keyboard-size-unit, 3.5px) * 23);
    width: calc(var(--keyboard-size-unit, 3.5px) * 80);
    height: calc(var(--keyboard-size-unit, 3.5px) * 84);

    &.left {
      left: calc(var(--keyboard-size-unit, 3.5px) * 8);
    }

    &.right {
      left: calc(var(--keyboard-size-unit, 3.5px) * 76);
    }
  }
}
</style>
