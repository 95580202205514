import { Formats, Format, KeyCode } from '@/config/keyboards/dataTypes';

const callCloudFunction = (cloudFunction: string): string => process.env.VUE_APP_CLOUD_FUNCTIONS_URL + cloudFunction;

const codeProxy = (keyCode: KeyCode<null>, navigator: Navigator, format: Format): KeyCode<null> => {
  if (format === Formats.ISO) {
    const UA = navigator.userAgent;

    // Safari
    const isMac = UA.indexOf('Mac') !== -1;
    const isSafari = UA.indexOf('Safari') !== -1;
    const isChrome = UA.indexOf('Chrome') !== -1;

    if (isMac && isSafari && !isChrome) {
      switch (keyCode) {
        case 'IntlBackslash':
          return 'Backquote';
        case 'Backquote':
          return 'IntlBackslash';
        default:
          return keyCode;
      }
      // switch (keyCode) {
      //   case 'IntlBackslash':
      //     return 'Backquote';
      //   case 'Backquote':
      //     return 'IntlBackslash';
      //   default:
      //     return keyCode;
      // }
    }
  }

  return keyCode;
};

// const keyProxy = (key: string, layout: LayoutProps): string => {
//   if (layout.format === Formats.ANSI && layout.name === layoutsRU.names.RussianApplePC) {
//     // Without Ё
//     switch (key) {
//       case 'ё':
//         return 'е'
//       case 'Ё':
//         return 'е'
//     }
//   }

//   return key;
// }

export { callCloudFunction, codeProxy };
