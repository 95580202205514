/* eslint-disable no-multi-spaces */
import {
  Systems, Formats, Languages, System, Format, LayoutProps,
} from '../dataTypes';
import {
  CharType as t, Key,
} from  '../key';
import {
  KeyboardLayout,
} from '../keyboardLayout';

const names = {
  Qwerty: 'qwerty',
  Typewriter: 'typewriter',
  RussianApple: 'russian-apple',
  RussianApplePC: 'russian-apple-pc',
};

const layouts: KeyboardLayout[] = [];

// Qwerty

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ANSI,
    language: Languages.RU,
    name: names.Qwerty,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '"'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '№'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, ';'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ':'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '?'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '/'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Symbol, '.'], [t.Symbol, ','])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ISO,
    language: Languages.RU,
    name: names.Qwerty,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '"'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '№'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, ';'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ':'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '?'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '/'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '\\'], [t.Symbol, '/'])],
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Symbol, '.'], [t.Symbol, ','])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ANSI,
    language: Languages.RU,
    name: names.RussianApplePC,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '['], [t.Symbol, ']'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '"'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '№'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, ';'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ':'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '?'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '/'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Symbol, '.'], [t.Symbol, ','])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ISO,
    language: Languages.RU,
    name: names.RussianApplePC,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '"'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '№'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, ';'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, '%'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ':'])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '?'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '*'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
      ['Backslash',     new Key([t.Symbol, '\\'], [t.Symbol, '/'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, ']'], [t.Symbol, '['])],
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Symbol, '.'], [t.Symbol, ','])],
    ]),
  ],
));

// // Russian (Apple)

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ANSI,
    language: Languages.RU,
    name: names.RussianApple,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, ']'], [t.Symbol, '['])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '"'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '№'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '%'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, ':'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ','])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '.'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, ';'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
      ['Backslash',     new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.OSX,
    format: Formats.ISO,
    language: Languages.RU,
    name: names.RussianApple,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '>'], [t.Symbol, '<'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '!'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '"'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '№'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '%'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, ':'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ','])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '.'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, ';'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '('])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, ')'])],
      ['Minus',         new Key([t.Symbol, '-'], [t.Symbol, '_'])],
      ['Equal',         new Key([t.Symbol, '='], [t.Symbol, '+'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
      ['Backslash',     new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, ']'], [t.Symbol, '['])],
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Symbol, '/'], [t.Symbol, '?'])],
    ]),
  ],
));

// Typewriter

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ANSI,
    language: Languages.RU,
    name: names.Typewriter,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '+'], [t.Symbol, '|'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '№'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '-'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '/'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '"'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, ':'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ','])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '.'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '_'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '?'])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, '%'])],
      ['Minus',         new Key([t.Symbol, '='], [t.Symbol, '!'])],
      ['Equal',         new Key([t.Symbol, '\\'], [t.Symbol, ';'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
      ['Backslash',     new Key([t.Symbol, '('], [t.Symbol, ')'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
    ]),
    new Map([
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
    ]),
  ],
));

layouts.push(new KeyboardLayout(
  {
    system: Systems.WIN,
    format: Formats.ISO,
    language: Languages.RU,
    name: names.Typewriter,
  },
  [
    new Map([
      ['Backquote',     new Key([t.Symbol, '+'], [t.Symbol, '|'])],
      ['Digit1',        new Key([t.Number, '1'], [t.Number, '№'])],
      ['Digit2',        new Key([t.Number, '2'], [t.Number, '-'])],
      ['Digit3',        new Key([t.Number, '3'], [t.Number, '/'])],
      ['Digit4',        new Key([t.Number, '4'], [t.Number, '"'])],
      ['Digit5',        new Key([t.Number, '5'], [t.Number, ':'])],
      ['Digit6',        new Key([t.Number, '6'], [t.Number, ','])],
      ['Digit7',        new Key([t.Number, '7'], [t.Number, '.'])],
      ['Digit8',        new Key([t.Number, '8'], [t.Number, '_'])],
      ['Digit9',        new Key([t.Number, '9'], [t.Number, '?'])],
      ['Digit0',        new Key([t.Number, '0'], [t.Number, '%'])],
      ['Minus',         new Key([t.Symbol, '='], [t.Symbol, '!'])],
      ['Equal',         new Key([t.Symbol, '\\'], [t.Symbol, ';'])],
    ]),
    new Map([
      ['KeyQ',          new Key([t.Letter, 'й'], [t.Letter, 'Й'])],
      ['KeyW',          new Key([t.Letter, 'ц'], [t.Letter, 'Ц'])],
      ['KeyE',          new Key([t.Letter, 'у'], [t.Letter, 'У'])],
      ['KeyR',          new Key([t.Letter, 'к'], [t.Letter, 'К'])],
      ['KeyT',          new Key([t.Letter, 'е'], [t.Letter, 'Е'])],
      ['KeyY',          new Key([t.Letter, 'н'], [t.Letter, 'Н'])],
      ['KeyU',          new Key([t.Letter, 'г'], [t.Letter, 'Г'])],
      ['KeyI',          new Key([t.Letter, 'ш'], [t.Letter, 'Ш'])],
      ['KeyO',          new Key([t.Letter, 'щ'], [t.Letter, 'Щ'])],
      ['KeyP',          new Key([t.Letter, 'з'], [t.Letter, 'З'])],
      ['BracketLeft',   new Key([t.Letter, 'х'], [t.Letter, 'Х'])],
      ['BracketRight',  new Key([t.Letter, 'ъ'], [t.Letter, 'Ъ'])],
    ]),
    new Map([
      ['KeyA',          new Key([t.Letter, 'ф'], [t.Letter, 'Ф'])],
      ['KeyS',          new Key([t.Letter, 'ы'], [t.Letter, 'Ы'])],
      ['KeyD',          new Key([t.Letter, 'в'], [t.Letter, 'В'])],
      ['KeyF',          new Key([t.Letter, 'а'], [t.Letter, 'А'])],
      ['KeyG',          new Key([t.Letter, 'п'], [t.Letter, 'П'])],
      ['KeyH',          new Key([t.Letter, 'р'], [t.Letter, 'Р'])],
      ['KeyJ',          new Key([t.Letter, 'о'], [t.Letter, 'О'])],
      ['KeyK',          new Key([t.Letter, 'л'], [t.Letter, 'Л'])],
      ['KeyL',          new Key([t.Letter, 'д'], [t.Letter, 'Д'])],
      ['Semicolon',     new Key([t.Letter, 'ж'], [t.Letter, 'Ж'])],
      ['Quote',         new Key([t.Letter, 'э'], [t.Letter, 'Э'])],
      ['Backslash',     new Key([t.Symbol, '('], [t.Symbol, ')'])],
    ]),
    new Map([
      ['IntlBackslash', new Key([t.Symbol, '('], [t.Symbol, ')'])],
      ['KeyZ',          new Key([t.Letter, 'я'], [t.Letter, 'Я'])],
      ['KeyX',          new Key([t.Letter, 'ч'], [t.Letter, 'Ч'])],
      ['KeyC',          new Key([t.Letter, 'с'], [t.Letter, 'С'])],
      ['KeyV',          new Key([t.Letter, 'м'], [t.Letter, 'М'])],
      ['KeyB',          new Key([t.Letter, 'и'], [t.Letter, 'И'])],
      ['KeyN',          new Key([t.Letter, 'т'], [t.Letter, 'Т'])],
      ['KeyM',          new Key([t.Letter, 'ь'], [t.Letter, 'Ь'])],
      ['Comma',         new Key([t.Letter, 'б'], [t.Letter, 'Б'])],
      ['Period',        new Key([t.Letter, 'ю'], [t.Letter, 'Ю'])],
      ['Slash',         new Key([t.Letter, 'ё'], [t.Letter, 'Ё'])],
    ]),
  ],
));

const defineByProps = (props: LayoutProps): KeyboardLayout | null => {
  for (let layoutIx = 0; layoutIx < layouts.length; layoutIx += 1) {
    const layout = layouts[layoutIx];

    if (layout.isEqual(props)) {
      return layout;
    }
  }

  return null;
};

const defineByKeyPress = (event: KeyboardEvent, system: System, format: Format): KeyboardLayout | null => {
  // ISO: ё, ANSI: .
  // WIN: Qwerty, Typewriter  OSX: Russian, Russian PC

  const props = {
    system,
    format,
    language: Languages.RU,
    name: '',
  };

  if (event.key === 'ё' && format === Formats.ISO) {
    switch (event.code) {
      case 'Backquote':
        props.name = system === Systems.WIN
          ? names.Qwerty : names.RussianApplePC;
        break;

      case 'Slash':
        props.name = system === Systems.WIN
          ? names.Typewriter : '';
        break;

      case 'Backslash':
        props.name = system === Systems.WIN
          ? '' : names.RussianApple;
        break;

      // if ISO, Safari swithes 'IntlBackslash' and 'Backquote'
      case 'IntlBackslash':
        props.name = system === Systems.OSX
          ? names.RussianApplePC : '';
        break;
      default:
        break;
    }
  } else if (event.key === '.' && format === Formats.ANSI) {
    switch (event.code) {
      case 'Digit7':
        if (system === Systems.WIN) {
          props.name = names.Typewriter;
        } else if (system === Systems.OSX && event.shiftKey) {
          props.name = names.RussianApple;
        }
        break;
      case 'Slash':
        props.name = system === Systems.WIN
          ? names.Qwerty : names.RussianApplePC;
        break;
      default:
        break;
    }
  }

  if (!props.name) {
    return null;
  }

  return defineByProps(props);
};

export {
  layouts, names, defineByKeyPress, defineByProps,
};
