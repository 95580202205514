import {
  KeyCode, LayoutProps, LayoutKeys,
} from './dataTypes';
import {
  Key,
} from './key';

class KeyboardLayout {
  readonly props: LayoutProps;

  readonly keys: LayoutKeys;

  constructor(props: LayoutProps, keys: LayoutKeys) {
    this.props = props;
    this.keys = keys;
  }

  public isEqual(props: LayoutProps): boolean {
    if (
      (this.props.system === props.system || this.props.system === 'ANY')
      && this.props.format === props.format
      && this.props.language === props.language
      && this.props.name === props.name
    ) {
      return true;
    }

    return false;
  }

  public getAllKeys(includeIntlBackslash = false): Map<KeyCode<null>, Key> {
    const allKeys = new Map();

    for (let ix = 0; ix < this.keys.length; ix += 1) {
      const row = this.keys[ix];

      row.forEach((val, key) => {
        allKeys.set(key, val);
      });
    }

    if (!includeIntlBackslash) {
      allKeys.delete('IntlBackslash');
    }

    return allKeys;
  }
}

export {
  KeyboardLayout,
};
