import { Languages } from './dataTypes';

import * as ru from './layouts/ru';
import * as en from './layouts/en';

const layouts = {
  [Languages.RU]: ru,
  [Languages.EN]: en,
};

export { layouts };
